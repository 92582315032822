import { FC, useState } from "react"
import { useSelector } from "react-redux"

import "./catalog-filters.scss"

import { useModal } from "context/modal-context"
import { Button } from "components/button/button"
import { RootState } from "store"
import { Header } from "components/header/header"
import { ItemFilterModel } from "common/models/ItemFilterModel"
import { PropertyModelOptions } from "./property-model-options/property-model-options"
import { StringModelOption } from "./string-model-options/string-model-options"
import { FlagPairModelOptions } from "./flag-pair-model-options/flag-pair-model-options"
import { HealthScoreOptions } from "./health-score-options/health-score-options"
import { FilterCategory } from "./health-score-options/filter-category/filter-category"
import { Loader } from "components/loader/loader"
import { AlertsScoreOptions } from "./alert-score-options/alert-score-options"
import { countFilters } from "utils"
import { CustomFieldModelOption } from "./custom-field-model-options/custom-field-model-options"

type Props = {
  callback: (filters: ItemFilterModel, reset?: boolean) => void
  initialFilters: ItemFilterModel
}

const fulfillmentTypes = ["1P", "2P", "3P"]

export const CatalogFilters: FC<Props> = ({ callback, initialFilters }: Props) => {
  const { unSetModal } = useModal()
  const [filters, setFilters] = useState(initialFilters || new ItemFilterModel())
  const auditCatalog = useSelector((state: RootState) => state.auditCatalog)
  const loadingFilters = useSelector(
    (state: RootState) => state.loading.effects.auditCatalog.getCatalogFilters,
  )

  return (
    <div className="catalog-filters">
      <div>
        <Header title="Filter Your Data" />
        <p className="mb-2">Easily narrow down the SKUS that you need.</p>
      </div>
      <div className="top-gradient" />
      <div className="catalog-filters-body">
        {!loadingFilters ? (
          <>
            <div>
              <FilterCategory title="Data Source">
                <StringModelOption
                  options={auditCatalog.filterModel?.dataSource || []}
                  filterName="dataSource"
                  filters={filters}
                  setFilters={setFilters}
                />
              </FilterCategory>
              <FilterCategory title="Audit Names">
                <PropertyModelOptions
                  options={auditCatalog.filterModel?.jobNames}
                  filterName="jobNames"
                  filters={filters}
                  setFilters={setFilters}
                />
              </FilterCategory>
              <FilterCategory title="Fulfillment Type">
                <StringModelOption
                  options={fulfillmentTypes || []}
                  filterName="fulfillmentType"
                  filters={filters}
                  setFilters={setFilters}
                />
              </FilterCategory>
              <FilterCategory title="Is Prime">
                <FlagPairModelOptions
                  trueLabel="True"
                  falseLabel="False"
                  filterName="isPrime"
                  filters={filters}
                  setFilters={setFilters}
                />
              </FilterCategory>
            </div>
            <div>
              <FilterCategory title="Brands">
                <PropertyModelOptions
                  options={auditCatalog.filterModel?.brands}
                  filterName="brands"
                  filters={filters}
                  setFilters={setFilters}
                />
              </FilterCategory>
              <FilterCategory title="Main Category">
                <PropertyModelOptions
                  options={auditCatalog.filterModel?.categories}
                  filterName="category"
                  filters={filters}
                  setFilters={setFilters}
                />
              </FilterCategory>
              <FilterCategory title="Sub-Category 1">
                <PropertyModelOptions
                  options={auditCatalog.filterModel?.firstSubCategories}
                  filterName="firstSubCategory"
                  filters={filters}
                  setFilters={setFilters}
                />
              </FilterCategory>
              <FilterCategory title="Sub-Category 2">
                <PropertyModelOptions
                  options={auditCatalog.filterModel?.secondSubCategories}
                  filterName="secondSubCategory"
                  filters={filters}
                  setFilters={setFilters}
                />
              </FilterCategory>
            </div>
            <div>
              <FilterCategory title="Flags">
                <AlertsScoreOptions filters={filters} setFilters={setFilters} />
              </FilterCategory>
              <FilterCategory title="Health Scores">
                <HealthScoreOptions
                  healthScoreFilters={auditCatalog.filterModel?.healthScoreFilters}
                  filters={filters}
                  setFilters={setFilters}
                />
              </FilterCategory>
              <FilterCategory title="Custom Fields" isInfoPointVisible>
                <CustomFieldModelOption
                  options={auditCatalog.filterModel?.customFields || []}
                  filters={filters}
                  setFilters={setFilters}
                />
              </FilterCategory>
            </div>
            <div>
              <FilterCategory title="System Tags">
                <StringModelOption
                  options={auditCatalog.filterModel?.systemTags || []}
                  filterName="tags"
                  filters={filters}
                  setFilters={setFilters}
                />
              </FilterCategory>
              <FilterCategory title="Custom Tags">
                <StringModelOption
                  options={auditCatalog.filterModel?.customTags || []}
                  filterName="tags"
                  filters={filters}
                  setFilters={setFilters}
                />
              </FilterCategory>
              <FilterCategory title="Keywords">
                <FlagPairModelOptions
                  trueLabel="Has Primary Keywords"
                  falseLabel="No Primary Keywords"
                  filterName="hasPrimaryKeywords"
                  filters={filters}
                  setFilters={setFilters}
                />
                <FlagPairModelOptions
                  trueLabel="Has Secondary Keywords"
                  falseLabel="No Secondary Keywords"
                  filterName="hasSecondaryKeywords"
                  filters={filters}
                  setFilters={setFilters}
                />
              </FilterCategory>
              <FilterCategory title="Additional content">
                <FlagPairModelOptions
                  trueLabel="Has 360 Spins"
                  falseLabel="No 360 Spins"
                  filterName="has360Spins"
                  filters={filters}
                  setFilters={setFilters}
                />
                <FlagPairModelOptions
                  trueLabel="Has Enhanced Content"
                  falseLabel="No Enhanced Content"
                  filterName="hasEnhancedContent"
                  filters={filters}
                  setFilters={setFilters}
                />
              </FilterCategory>
            </div>
          </>
        ) : (
          <Loader message="Loading..." />
        )}
      </div>
      <div className="bottom-gradient" />
      <div className="catalog-filters-footer">
        <Button variant="outlined" onClick={unSetModal}>
          Cancel
        </Button>
        <div className="grid grid-flow-col gap-x-3">
          <Button
            type="submit"
            variant="outlined"
            disabled={countFilters(filters) === 0}
            onClick={() => setFilters(new ItemFilterModel())}
          >
            Reset Filters
          </Button>
          <Button
            type="submit"
            variant="primary"
            onClick={() => {
              const cleanCustomFieldsFilter = {
                ...filters,
                customFields: filters.customFields?.filter((f) => f.values),
              }
              setFilters(cleanCustomFieldsFilter)
              callback(cleanCustomFieldsFilter)
              unSetModal()
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    </div>
  )
}
