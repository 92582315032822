import React, { useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"
import { Checkbox } from "components/form-components/checkbox/checkbox"
import { Icon, ICONS } from "components/icon/icon"

interface SelectOption {
  label: string
  value: string
}

interface CustomSelectProps {
  options: SelectOption[]
  isDisabled?: boolean
  selectedOptions: SelectOption[]
  setSelectedOptions: (values: SelectOption[]) => void
}

export const CustomSelect: React.FC<CustomSelectProps> = ({
  options,
  isDisabled = false,
  selectedOptions,
  setSelectedOptions,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const handleOptionToggle = (option: SelectOption) => {
    const isSelected = selectedOptions.some((sel) => sel.value === option.value)

    const updatedSelected = isSelected
      ? selectedOptions.filter((sel) => sel.value !== option.value)
      : [...selectedOptions, option]

    setSelectedOptions(updatedSelected)
  }

  const handleInputClick = () => {
    if (!isDisabled) {
      setIsMenuOpen(!isMenuOpen)
    }
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setIsMenuOpen(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  const dropdown = (
    <div
      ref={dropdownRef}
      className="absolute z-50 mt-1 bg-white border rounded shadow max-h-60 w-[175px] overflow-y-auto"
      style={{
        top: inputRef.current?.getBoundingClientRect().bottom ?? 0 + window.scrollY,
        right: window.innerWidth - (inputRef.current?.getBoundingClientRect().right ?? 0),
      }}
    >
      {options.map((option) => {
        const isSelected = selectedOptions.some((sel) => sel.value === option.value)
        const isDisabled = selectedOptions.length === 1 && isSelected

        return (
          <label key={option.value} className="flex items-center gap-3 hover:bg-gray-100">
            <div className="w-[14px]">
              <Checkbox
                value={isSelected}
                disabled={isDisabled}
                onChange={() => handleOptionToggle(option)}
                small
              />
            </div>
            <div
              className="overflow-hidden whitespace-nowrap text-ellipsis text-caption-2 font-semibold"
              title={option.label}
            >
              {option.label}
            </div>
          </label>
        )
      })}
    </div>
  )

  return (
    <div className="relative w-full max-w-sm">
      <input
        ref={inputRef}
        type="text"
        className={`w-full h-6 p-2 border rounded ${
          isDisabled ? "opacity-50" : ""
        } border-gray-300 text-caption-2 font-semibold w-[150px] cursor-default`}
        value={
          selectedOptions.length > 1
            ? `${selectedOptions.length} values`
            : selectedOptions.map((option) => option.label)
        }
        onClick={handleInputClick}
        readOnly
        disabled={isDisabled}
        placeholder="Select Value(s)"
      />

      <div onClick={handleInputClick}>
        <Icon
          icon={isMenuOpen ? ICONS.CHEVRON_UP : ICONS.CHEVRON_DOWN}
          size={5}
          className="absolute right-2 top-0.5 transition-transform duration-200"
        />
      </div>

      {isMenuOpen && createPortal(dropdown, document.body)}
    </div>
  )
}
