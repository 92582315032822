import React, { FC, Suspense, useEffect } from "react"
import "./workspaces.scss"
import { Route, Routes } from "react-router-dom"
import { SuspenseLoader } from "../../components/suspense-loader/suspense-loader"
import { lazyLoadRetry } from "../../utils/lazyLoadRetry"
import { abortController } from "api/api"

const ManageWorkspaces = React.lazy(
  lazyLoadRetry(() => import("./manage-workspaces/manage-workspaces")),
)
const CurrentWorkpace = React.lazy(
  lazyLoadRetry(() => import("./current-workspace/current-workspace")),
)
const ManagePlans = React.lazy(
  lazyLoadRetry(() => import("./manage-workspaces/manage-plans/manage-plans")),
)

export const Workspaces: FC = () => {
  useEffect(() => {
    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <div className="workspaces">
      <Suspense fallback={<SuspenseLoader delay={500} />}>
        <Routes>
          <Route path="/" element={<ManageWorkspaces />} />
          <Route path="/:workspaceId/edit/*" element={<CurrentWorkpace />} />
          <Route path="/:workspaceId/view/*" element={<CurrentWorkpace isViewOnly />} />
          <Route path="/:workspaceId/manage-plans" element={<ManagePlans />} />
        </Routes>
      </Suspense>
    </div>
  )
}
