import {
  dataSamplePreviewApi,
  getCatalogHeadersApi,
} from "api/endpoints/data-mapping-import.api"
import { Button } from "components/button/button"
import { Input } from "components/form-components/input/input"
import { Select } from "components/form-components/select/select"
import { ICONS, Icon } from "components/icon/icon"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "store"
import { FourthStepTypes } from "../fieldMappingPage"

type Mapping = {
  [key: string]: any
}

type StepThreeProps = {
  step: number
  setStep: (step: number) => void
  mappings: Mapping[]
  setMappings: React.Dispatch<React.SetStateAction<Mapping[]>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  dataMappingId: string
  setFourthStepData: React.Dispatch<React.SetStateAction<FourthStepTypes>>
  onCancel: () => void
}

export const StepThree = ({
  step,
  setStep,
  mappings,
  setMappings,
  setLoading,
  dataMappingId,
  setFourthStepData,
  onCancel,
}: StepThreeProps) => {
  const [isNextDisabled, setIsNextDisabled] = useState(true)
  const [catalogHeaders, setCatalogHeaders] = useState<string[]>([])

  const [selectedHeaders, setSelectedHeaders] = useState<string[]>(
    mappings?.map((el) => Object.values(el))?.flat(),
  )
  const selectedWorkspaceId = useSelector(
    (state: RootState) => state.workspaces.selectedWorkspaceId,
  )

  const getAvailableHeaders = (value: string) => {
    return catalogHeaders.filter(
      (header) =>
        header === value || !selectedHeaders.includes(header) || header === "Do Not Map",
    )
  }

  const renderMappingStatus = (value: string | null) => {
    if (value === null) {
      return (
        <div className="w-32 text-center">
          <div className="text-gray-300">map to</div>
          <div className="mx-4 border border-dashed border-gray-300"></div>
        </div>
      )
    }

    if (value === "Do Not Map") {
      return (
        <div className="w-32 flex items-center justify-center">
          <div className="text-red-600">
            <Icon icon={ICONS.CANCEL_SOLID_ICON} size={6} />
          </div>
        </div>
      )
    }

    return (
      <div className="w-32 text-center">
        <div className="text-green-600">mapped</div>
        <div className="border border-solid border-green-600"></div>
      </div>
    )
  }

  const handleMapSelect = (key: string) => (option: { value: string }) => {
    const usedHeaders: string[] = []
    const newMapping = mappings.map((el) => {
      if (Object.keys(el)[0] === key) {
        return {
          [key]: option?.value || null,
        }
      }
      return el
    })
    newMapping.map((el) => {
      const header = Object.values(el)[0]
      if (header !== null) {
        usedHeaders.push(header)
      }
    })
    setMappings(newMapping)
    setSelectedHeaders(usedHeaders)
  }

  const onSubmit = () => {
    setLoading(true)
    if (selectedWorkspaceId) {
      const newMappings = mappings.map((mapping) => {
        const [key, value] = Object.entries(mapping)[0]
        return { fileHeader: key, catalogHeader: value }
      })
      const payload = {
        mappings: newMappings,
        dataMappingId: dataMappingId,
      }
      dataSamplePreviewApi(selectedWorkspaceId, payload).then((res) => {
        setFourthStepData({ rows: res, dataMappingId })
        setLoading(false)
        setStep(step + 1)
      })
    }
  }

  useEffect(() => {
    const allValid = mappings.every((mapping) => Object.values(mapping)[0] !== null)
    setIsNextDisabled(!allValid)
  }, [mappings])

  useEffect(() => {
    if (selectedWorkspaceId) {
      getCatalogHeadersApi(selectedWorkspaceId).then((resp) =>
        setCatalogHeaders(["Do Not Map", ...resp]),
      )
    }
  }, [selectedWorkspaceId])

  return (
    <div className="flex flex-col h-full">
      <div className="step-body">
        <div className="flex justify-between pb-3 border-b border-gray-200 mb-[30px]">
          <div className="flex flex-col">
            <div className="text-title-3 font-bold text-gray-900 pb-[5px]">
              Edit Mapping
            </div>
            <div className="text-card text-gray-500 font-medium">
              <span>
                Map the columns from the uploaded file with the predefined ones from the
                application. To continue to the
              </span>
              <span className="text-gray-900"> next step, </span>
              <span>you must select an </span>
              <span className="text-gray-900">option </span>
              <span>for </span>
              <span className="text-gray-900">each row.</span>
            </div>
          </div>
          <Button variant="outlined" className="self-end" onClick={onCancel}>
            Cancel Upload
          </Button>
        </div>
        <div className="flex overflow-auto">
          <div className="mx-auto">
            {mappings.map((el, index) => {
              const [key, value] = Object.entries(el)[0]
              return (
                <div className="flex items-center mb-4 mx-auto" key={`${index}-${key}`}>
                  <Input value={key} readOnly className="w-[280px] max-w-[280px]" />
                  {renderMappingStatus(value)}
                  <Select
                    options={getAvailableHeaders(value ?? "")?.map((header) => {
                      return {
                        value: header,
                        label: header,
                      }
                    })}
                    value={value}
                    onChange={handleMapSelect(key)}
                    className="w-[352px]"
                    menuPlacement="auto"
                    hideSelectedValues
                    isClearable={true}
                    isSearchable={true}
                    placeholder="None"
                    optionRender={(option: any) => (
                      <div
                        className={`${
                          option.label === "Do Not Map" ? "text-red-600" : ""
                        }`}
                      >
                        {option.label}
                      </div>
                    )}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="step-button-bar">
        <Button
          variant="outlined"
          onClick={() => {
            setStep(step - 1)
          }}
          className="mr-3"
        >
          Back
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            onSubmit()
          }}
          disabled={isNextDisabled}
        >
          Next Step
        </Button>
      </div>
    </div>
  )
}
