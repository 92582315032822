export enum RetailersFilterBy {
  FrequentAndOthers,
  FrequentlyUsed,
  Favorite,
  Custom,
  AZ,
  ZA,
}

export enum RetailersFilterByNew {
  FrequentAndOthers,
  FrequentlyUsed,
  Favorite,
  Custom,
  AZ,
  ZA
}