import React from "react"
import { NavigateFunction } from "react-router-dom"
import { IPublicClientApplication } from "@azure/msal-browser"
import { addMinutes, format } from "date-fns"

import * as colors from "./colors"
import {
  SummaryColors,
  SummaryFormat,
  defaultScoreDescriptions,
  invalidInputCharacters,
  months,
} from "./constants"
import { gray } from "./colors"
import { Base64DownloadModel } from "../common/models/Base64DownloadModel"
import { ItemFilterModel } from "common/models/ItemFilterModel"
import { RetailerModel } from "common/models/RetailerModel"
import { RetailerSelectorOption } from "common/types/RetailerSelectorOption.type"
import { ItemDashboardFilterModel } from "common/models/ItemDashboardFilterModel"
import {
  RubricDetailsModel,
  RubricDetailsModelForm,
} from "common/models/RubricDetailsModel"
import { SectionIdentifier } from "common/types/SectionIdentifier.type"
import { RuleIdentifier } from "common/types/RuleIdentifier.type"

export type ScoreColor = {
  score: number
  percent: number
  color: string
}

export const clamp = (value: number, low: number, high: number): number => {
  return value < low ? low : value > high ? high : value
}

export const scoreToColor = (score: number | string | null = 0): string => {
  let index = -1
  if (score === null) {
    return gray[400]
  } else {
    do {
      index++
    } while (
      index <= defaultScoreDescriptions.length - 1 &&
      defaultScoreDescriptions[index].score < score
    )

    return index - 1 &&
      index <= defaultScoreDescriptions.length - 1 &&
      defaultScoreDescriptions[index].score > score &&
      defaultScoreDescriptions[index - 1].score < score
      ? defaultScoreDescriptions[index - 1].color
      : index > defaultScoreDescriptions.length - 1
      ? defaultScoreDescriptions[defaultScoreDescriptions.length - 1].color
      : defaultScoreDescriptions[index].color
  }
}

export const percentToColor = (percent: number | string = 0): string => {
  let index = -1
  do {
    index++
  } while (
    index <= defaultScoreDescriptions.length - 1 &&
    defaultScoreDescriptions[index].percent < percent
  )

  return index - 1 &&
    defaultScoreDescriptions[index].percent > percent &&
    defaultScoreDescriptions[index - 1].percent < percent
    ? defaultScoreDescriptions[index - 1].color
    : index > defaultScoreDescriptions.length - 1
    ? defaultScoreDescriptions[defaultScoreDescriptions.length - 1].color
    : defaultScoreDescriptions[index].color
}

export const scoreToStatus = (score: number | string | null = 0): string => {
  let index = -1
  if (score === null) {
    return "--"
  } else {
    do {
      index++
    } while (
      index <= defaultScoreDescriptions.length - 1 &&
      defaultScoreDescriptions[index].score < score
    )

    return index - 1 &&
      index <= defaultScoreDescriptions.length - 1 &&
      defaultScoreDescriptions[index].score > score &&
      defaultScoreDescriptions[index - 1].score < score
      ? defaultScoreDescriptions[index - 1].status
      : index > defaultScoreDescriptions.length - 1
      ? defaultScoreDescriptions[defaultScoreDescriptions.length - 1].status
      : defaultScoreDescriptions[index].status
  }
}

export const percentToStatus = (percent: number | string = 0): string => {
  let index = -1
  do {
    index++
  } while (
    index <= defaultScoreDescriptions.length - 1 &&
    defaultScoreDescriptions[index].percent < percent
  )

  return index - 1 &&
    defaultScoreDescriptions[index].percent > percent &&
    defaultScoreDescriptions[index - 1].percent < percent
    ? defaultScoreDescriptions[index - 1].status
    : index > defaultScoreDescriptions.length - 1
    ? defaultScoreDescriptions[defaultScoreDescriptions.length - 1].status
    : defaultScoreDescriptions[index].status
}

export const normalizeRange = (range: {
  low: number
  high: number
}): { low: number; high: number } => {
  if (range.low < 0 && range.high > 0) {
    return { low: 0, high: range.high - range.low }
  }
  if (range.low < 0 && range.high < 0) {
    return { low: -range.high, high: -range.low }
  }
  return range
}

//? range(-100,100) score 0 => yellow
export const rangeToColor = (
  score: number | undefined,
  range: {
    low: number
    high: number
  },
): string => {
  let index = 0
  if (score) {
    const normScore =
      range.low < 0 && range.high > 0
        ? score - range.low
        : range.low < 0 && range.high < 0
        ? -score
        : score
    const normRange = normalizeRange(range)
    const clampedScore = clamp(normScore, normRange.low, normRange.high)

    const step = Math.floor(
      clampedScore / ((normRange.high - normRange.low) / defaultScoreDescriptions.length),
    )

    index = clamp(step, 0, defaultScoreDescriptions.length - 1)
  }
  return defaultScoreDescriptions[index].color
}

export function findByKey(array: any[], key: string, value: any) {
  if (array) {
    // temp fix for product scores till BE ready
    return array?.find((item) => item[key].includes(value))
  } else {
    return undefined
  }
}

export const enumToArray = (enumObj: any) => {
  return (
    Object.keys(enumObj)
      .filter((key) => isNaN(Number(key)))
      .map((key: string) => ({
        value: Number(enumObj[key]),
        label: key.replaceAll(/([A-Z])/gm, " $1").trim(),
      })) || []
  )
}

export const getValue = (obj: any, accessor: any, value?: any): any => {
  if (!obj) {
    return ""
  }
  if (typeof accessor === "string") {
    return getValue(obj, accessor.split("."), value)
  } else if (accessor.length === 1 && value !== undefined) {
    return (obj[accessor[0]] = value)
  } else if (accessor.length === 0) {
    return obj
  } else {
    return getValue(obj[accessor[0]], accessor.slice(1), value)
  }
}

export const setThemeColors = (
  primaryColor: keyof typeof colors,
  secondaryColor: keyof typeof colors,
  tertiaryColor: keyof typeof colors,
) => {
  Object.keys(colors[primaryColor]).forEach((key: any) => {
    document.documentElement.style.setProperty(
      `--primary-${key as string}`,
      (colors[primaryColor] as any)[key],
    )
  })
  Object.keys(colors[secondaryColor]).forEach((key: any) => {
    document.documentElement.style.setProperty(
      `--secondary-${key as string}`,
      (colors[secondaryColor] as any)[key],
    )
  })
  Object.keys(colors[tertiaryColor]).forEach((key: any) => {
    document.documentElement.style.setProperty(
      `--tertiary-${key as string}`,
      (colors[tertiaryColor] as any)[key],
    )
  })

  document.documentElement.style.setProperty("--primary", colors[primaryColor]["900"])
  document.documentElement.style.setProperty("--secondary", colors[secondaryColor]["500"])
  document.documentElement.style.setProperty("--tertiary", colors[primaryColor]["600"])
}

export const generateGuid = (): string => {
  const s4 = () => {
    const rand = Math.floor((1 + Math.random()) * 0x10000)
    return rand.toString(16).substring(1)
  }
  return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4()
}

export const downloadCsv = (data: any, headers: string, fileName: string) => {
  const csvData = data
  let csv = headers

  csvData.forEach((row: any) => {
    csv += row.join(",")
    csv += "\n"
  })

  const hiddenElement = document.createElement("a")
  hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv)
  hiddenElement.target = "_blank"
  hiddenElement.download = fileName
  hiddenElement.click()
}

export const downloadDocuments = (url: string, fileName: string) => {
  const hiddenElement = document.createElement("a")
  hiddenElement.href = url
  hiddenElement.target = "_blank"
  hiddenElement.download = fileName
  hiddenElement.click()
}

export const downloadBase64Csv = ({ file }: Base64DownloadModel) => {
  if (file) {
    const linkSource = `data:application/csv;base64,${file}`
    const downloadLink = document.createElement("a")
    const fileName = "sample_file.csv"

    downloadLink.href = linkSource
    downloadLink.download = fileName
    downloadLink.click()
  }
}

export const downloadBase64Xlsx = ({ file }: Base64DownloadModel) => {
  if (file) {
    const linkSource = `data:application/xlsx;base64,${file}`
    const downloadLink = document.createElement("a")
    const fileName = "sample_file.xlsx"

    downloadLink.href = linkSource
    downloadLink.download = fileName
    downloadLink.click()
  }
}

export const range = (start: number, end: number) => {
  return Array.from({ length: end - start }, (v, k) => k + start)
}

export const getMonth = (date: string) => {
  const newDate = new Date(date)

  return months[newDate.getMonth()]
}

export const getTextWidth = (text: string) => {
  const canvas = document.createElement("canvas")
  const context = canvas.getContext("2d")

  if (context) {
    context.font = getComputedStyle(document.body).font
    return context.measureText(text).width + 25
  } else {
    return 100
  }
}
export const getHourFormatAmPm = (hour: number, minutes: number) => {
  if (hour < 12 && hour !== 0) {
    return (
      (hour < 10 ? "0" + hour : hour) +
      ":" +
      (minutes < 10 ? "0" + minutes : minutes) +
      " am"
    )
  } else if (hour === 0) {
    return 12 + ":" + (minutes < 10 ? "0" + minutes : minutes) + " am"
  } else if (hour === 12) {
    return 12 + ":" + (minutes < 10 ? "0" + minutes : minutes) + " pm"
  } else if (hour > 12) {
    return (
      (hour - 12 < 10 ? "0" + (hour - 12) : hour - 12) +
      ":" +
      (minutes < 10 ? "0" + minutes : minutes) +
      " pm"
    )
  }
}

export const toFixedNoRounding = (number: number | string) => {
  return Number(number.toString().match(/^\d+(?:\.\d{0,2})?/))
}

export const toFixedRoundingReports = (number: number | string) => {
  const fixedDecimalNumber = Number(number.toString().match(/^\d+(?:\.\d{0,1})?/))
  if (fixedDecimalNumber % 1 === 0) {
    return fixedDecimalNumber + ".0"
  } else {
    return fixedDecimalNumber
  }
}

export const truncateString = (string: string, length: number) => {
  if (string.length > length) {
    return string.substring(0, length) + "..."
  }
  return string
}

export const logout = (
  instance: IPublicClientApplication,
  navigate: NavigateFunction,
) => {
  localStorage.clear()
  navigate("/")
  instance.logoutRedirect()
}

export const enumToOptions = (initialEnum: any) => {
  const allEnumEntries = Object.entries(initialEnum)
  const needEntries = allEnumEntries.slice(
    allEnumEntries.length / 2,
    allEnumEntries.length,
  )
  return needEntries.map((i) => ({ value: i[1], label: i[0] }))
}

export const renderWithDollarSign = (data: string) =>
  data?.includes("$") ? data : "$" + data

export const kebabToStartCase = (s: string) =>
  s.replace(/-/g, " ").replace(/^\w|\s\w/g, (l) => l.toUpperCase())

export const countFilters = (filters: ItemFilterModel) => {
  return Object.keys(filters).reduce((acc, key) => {
    const value = filters[key as keyof ItemFilterModel]
    if (Array.isArray(value) && value.length > 0) {
      return acc + value.length
    } else if (typeof value === "boolean" && value !== undefined) {
      return acc + 1
    }
    return acc
  }, 0)
}

export const getTotalFiltersNumber = (
  currentFilter: ItemFilterModel,
  currentContentInsightsFilter: ItemDashboardFilterModel | null,
) => countFilters(currentFilter) + (currentContentInsightsFilter === null ? 0 : 1)

export const retailersToOptions = (retailers: RetailerModel[]) => {
  return retailers.map((r: any) => ({
    value: r.id,
    label: r.name,
    logoUrl: r.logoUrl === null ? "" : r.logoUrl,
    handle: r.handle === null ? r.name : r.handle,
    customName: r.customName === null ? "" : r.customName,
    customDescription: r.customDescription === null ? "" : r.customDescription,
    notes: r.notes,
  }))
}

export const filterOptionsBySearch = (
  options: RetailerSelectorOption[],
  searchQuery: string,
) =>
  options.filter((option) => {
    const searchResult = searchQuery
      ? option.label.toLowerCase().includes(searchQuery)
      : true
    return searchResult
  })

export const findSectionRule = (
  rubric: RubricDetailsModel | RubricDetailsModelForm | null,
  sectionIdentifier: SectionIdentifier | string,
  ruleIdentifier: RuleIdentifier | string,
) =>
  rubric?.sections
    ?.find((section) => section.identifier === sectionIdentifier)
    ?.rules.find((rule) => rule.identifier === ruleIdentifier)

export const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) =>
  invalidInputCharacters.has(event.key) && event.preventDefault()

export const onWheel = (event: React.WheelEvent<HTMLInputElement>) =>
  event.currentTarget.blur()

export const resolvePath = (object: any, path: string) =>
  path
    .split(/[.[\]'"]/)
    .filter((p) => p)
    .reduce((o, p) => (o ? o[p] : ""), object)

export const toUTC = (time: string) => {
  const date = new Date(time)
  return typeof time === "string" && date.toDateString() !== "Invalid Date"
    ? format(addMinutes(date, date.getTimezoneOffset()), "MM/dd/yyyy")
    : ""
}

export const formatPercentage = (value: number) => (value % 1 ? value.toFixed(1) : value)

export const getColumnSummary = (summaryFormat: string, summary: any): any => {
  if (summaryFormat === SummaryFormat.SCORE) {
    return {
      color: scoreToColor(Number(summary)),
      summary: toFixedNoRounding(summary),
    }
  } else {
    return {
      color: SummaryColors[`${summaryFormat?.toUpperCase()}`],
      summary:
        summaryFormat === SummaryFormat.PERCENT
          ? `${toFixedNoRounding(summary)}%`
          : summaryFormat === SummaryFormat.PRICE
          ? `$${toFixedNoRounding(summary)}`
          : toFixedNoRounding(summary),
    }
  }
}

export const formatSearchString = (str: string) => str.replace(/ /g, "").toLowerCase()

export const useStopPropagation =
  (callback: () => void) => (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    callback()
  }

export const validateWeighting = (e: React.ChangeEvent<HTMLInputElement>) => {
  const numericValue = Number(e.target.value || 0)
  if (numericValue < 0 || numericValue > 100) {
    return ""
  }
  return numericValue.toString()
}

export const newScorecardSectionsTemplateRows = (arr: Array<any>) =>
  `repeat(${arr.length}, 1fr) ${6 - arr.length}fr`

export const getLabelMaxWidthForScreen = (screenWidth: number) => {
  if (screenWidth <= 1300) {
    return 50
  }
  if (screenWidth <= 1300 && screenWidth < 1600) {
    return 80
  }

  return screenWidth < 2048 ? 100 : 250
}

export const isEllipsisActive = (el: HTMLElement | null) =>
  el ? el?.offsetWidth >= el?.scrollWidth : false
