import { RubricDetailsModel } from "common/models/RubricDetailsModel"
import { RubricCreationModel } from "common/models/RubricCreationModel"
import { RubricModel } from "common/models/RubricModel"
import { RubricModelPageResultModel } from "common/models/RubricModelPageResultModel"
import { RetailerRubricModel } from "common/models/RetailerRubricModel"
import { RetailerRubricAllocationModel } from "common/models/RetailerRubricAllocationModel"
import { API } from "../api"
import { RubricsGetModel } from "common/models/RubricsGetModel"

export const getRubricApi = (rubricId: string): Promise<RubricDetailsModel> =>
  API().get(`rubrics/${rubricId}`)

export const createRubricApi = (
  workspaceId: string,
  body: RubricCreationModel,
): Promise<RubricDetailsModel> => API().post(`workspaces/${workspaceId}/rubrics`, body)

export const getWorkspaceRubricsApi = (workspaceId: string): Promise<RubricModel[]> =>
  API().get(`workspaces/${workspaceId}/rubrics`)

export const saveRubricChagesApi = (
  workspaceId: string,
  rubricId: string,
  body: RubricCreationModel,
): Promise<RubricDetailsModel> =>
  API().put(`workspaces/${workspaceId}/rubrics/${rubricId}`, body)

export const deleteRubricApi = (
  workspaceId: string,
  rubricId: string,
): Promise<boolean> => API().delete(`workspaces/${workspaceId}/rubrics/${rubricId}`)

export const getWorkspaceRubricsApiPaged = (
  workspaceId: string,
  body: RubricsGetModel,
): Promise<RubricModelPageResultModel> =>
  API().post(`workspaces/${workspaceId}/rubrics/paged/new`, body)

export const getDefaultRubricsApi = (
  workspaceId: string,
): Promise<RetailerRubricModel[]> =>
  API().get(`workspaces/${workspaceId}/rubrics/defaultRubrics`)

export const getNewDefaultRubricsApi = (): Promise<RubricDetailsModel> =>
  API().get(`rubrics/default/newRubric`)

export const updateDefaultRubricApi = (
  workspaceId: string,
  body: RetailerRubricAllocationModel[],
): Promise<boolean> => API().put(`workspaces/${workspaceId}/rubrics/setdefault`, body)

export const deleteRetailerCategoryAllocationApi = (
  workspaceId: string,
  retailerCategoryId: string,
): Promise<boolean> =>
  API().delete(`workspaces/${workspaceId}/rubrics/${retailerCategoryId}/remove/category`)
