import { Controller, useFormContext } from "react-hook-form"
import { Icon, ICONS } from "components/icon/icon"
import { NewAuditCard } from "../../../pages/audits/new-audit/new-audit-card/new-audit-card"
import { RetailerSelectorOption } from "common/types/RetailerSelectorOption.type"
import "./retailer-selector.scss"
import Tippy from "@tippyjs/react"
import { isEllipsisActive } from "utils"

type Props = {
  options: RetailerSelectorOption[]
  name: string
  required?: boolean
  disabled?: boolean
}

export const EllipsisRetailerLabel = ({ label, id }: { label: string; id: string }) => {
  return (
    <Tippy
      interactive
      appendTo={document.body}
      content={
        <div className="bg-white p-3 rounded-md shadow-lg break-words">{label}</div>
      }
      placement="bottom"
      maxWidth={"458px"}
      disabled={isEllipsisActive(document.getElementById(`title-${id}`))}
    >
      <div id={`title-${id}`} className="truncate text-gray-900 text-card -mt-2">
        {label}
      </div>
    </Tippy>
  )
}

export const RetailerSelector: React.FC<Props> = ({
  options,
  name,
  required,
  disabled = false,
}) => {
  const { control, watch } = useFormContext()

  const handleRetailerSelect = (
    option: RetailerSelectorOption,
    onChange: (event: RetailerSelectorOption) => void,
  ) => {
    if (name === "availableFor") {
      const selectedRetailers = watch(name)
      const isOptionSelected = selectedRetailers.find(
        (retailer: RetailerSelectorOption) => retailer.value === option.value,
      )

      const updatedRetailers = isOptionSelected
        ? selectedRetailers.filter(
            (retailer: RetailerSelectorOption) => retailer.value !== option.value,
          )
        : [...selectedRetailers, option]

      onChange(updatedRetailers)
    } else {
      onChange(option)
    }
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field: { onChange, value } }) => {
        return options.length ? (
          <div className="retailer-selector">
            {options?.map((option) => (
              <NewAuditCard
                key={option.value}
                selected={
                  name === "availableFor"
                    ? value?.find(
                        (retailer: RetailerSelectorOption) =>
                          retailer.value === option.value,
                      )
                    : value?.value === option.value
                }
                disabled={disabled}
                checkbox={name === "availableFor"}
                onClick={() => handleRetailerSelect(option, onChange)}
                title={
                  <div className="pb-3 min-h-12 max-h-12">
                    {option.logoUrl ? (
                      <img
                        className="h-full w-full object-contain"
                        src={option.logoUrl}
                        alt={option.label}
                      />
                    ) : (
                      <Icon
                        className="text-gray-300 mx-auto"
                        size={10}
                        icon={ICONS.CIRCLE_STACK}
                      />
                    )}
                  </div>
                }
                body={<EllipsisRetailerLabel label={option.label} id={option.value} />}
              />
            ))}
          </div>
        ) : (
          <div className="grid place-items-center text-gray-900 h-full mt-6">
            <div className="flex gap-[13px]">
              <Icon icon={ICONS.BELL} />
              No item was found.
            </div>
          </div>
        )
      }}
    />
  )
}
