import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootDispatch, RootState } from "store"
import { filterOptionsBySearch, retailersToOptions } from "utils"
import { SearchInput } from "components/form-components/search-input/search-input"
import { Loader } from "components/loader/loader"
import { RetailerSelectorOption } from "common/types/RetailerSelectorOption.type"
import { RetailerSelectorUncontrolled } from "components/select-retailer/retailer-selector-uncontrolled"
import { Button } from "components/button/button"
import { RetailersFilterBy } from "common/enums/RetailersFilterBy.enum"
import { AuditType } from "common/enums/AuditType.enum"
import { Select, SelectOption } from "components/form-components/select/select"
import { retailersFilterByOptions } from "utils/constants"
import { useModal } from "context/modal-context"
import { SourceModal } from "components/source-modal/source-modal"
import { RetailerSelectionModal } from "./retailer-selection-modal"

type Props = {
  disabled?: boolean
  selectedRetailers: RetailerSelectorOption[]
  setSelectedRetailers: React.Dispatch<React.SetStateAction<RetailerSelectorOption[]>>
  selectedUploadType: UploadType
}

export enum UploadType {
  uploadMasterVersionContent = "0",
  uploadAsASource = "1",
}

export const SelectRetailerUncontrolled = ({
  disabled = false,
  selectedRetailers,
  setSelectedRetailers,
  selectedUploadType,
}: Props) => {
  const { setModal, unSetModal } = useModal()

  const [searchQuery, setSearchQuery] = useState("")
  const [isScrolled, setIsScrolled] = useState(false)
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [filterBy, setFilterBy] = useState(RetailersFilterBy.FrequentAndOthers)

  const dispatch = useDispatch<RootDispatch>()
  const elementRef = useRef<HTMLDivElement>(null)
  const allRetailers = useSelector((state: RootState) => state.retailers.allRetailers)
  const frequentlyUsedRetailers = useSelector(
    (state: RootState) => state.retailers.frequentlyUsedRetailers,
  )
  const customRetailers = useSelector(
    (state: RootState) => state.retailers.customRetailers,
  )
  const favoriteRetailers = useSelector(
    (state: RootState) => state.retailers.favoriteRetailers,
  )
  const selectedAudit = useSelector((state: RootState) => state.audits.selectedAudit)

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value?.toLowerCase())
  }

  const getRetailerCards = (): JSX.Element => {
    switch (filterBy) {
      case RetailersFilterBy.FrequentAndOthers: {
        const frequentlyUsedOptions = retailersToOptions(frequentlyUsedRetailers)
        const filteredFrequentlyUsedOptions = filterOptionsBySearch(
          frequentlyUsedOptions,
          searchQuery,
        )
        const frequentRetailersIdsSet = new Set(
          frequentlyUsedRetailers.map((retailer) => retailer.id),
        )
        const otherRetailers = allRetailers.filter(
          (retailer) => !frequentRetailersIdsSet.has(retailer.id),
        )
        const otherRetailerOptions = retailersToOptions(otherRetailers)
        const filteredOtherRetailers = filterOptionsBySearch(
          otherRetailerOptions,
          searchQuery,
        )
        return (
          <div>
            <p className="text-callout font-semibold mb-3">Frequently used</p>
            <RetailerSelectorUncontrolled
              uploadType={selectedUploadType}
              options={filteredFrequentlyUsedOptions}
              disabled={disabled}
              selectedRetailers={selectedRetailers}
              setSelectedRetailers={setSelectedRetailers}
            />
            <p className="text-callout font-semibold my-3">Others</p>
            <RetailerSelectorUncontrolled
              uploadType={selectedUploadType}
              options={filteredOtherRetailers}
              disabled={disabled}
              selectedRetailers={selectedRetailers}
              setSelectedRetailers={setSelectedRetailers}
            />
          </div>
        )
      }
      case RetailersFilterBy.FrequentlyUsed: {
        const frequentlyUsedOptions = retailersToOptions(frequentlyUsedRetailers)
        const filteredFrequentlyUsedOptions = filterOptionsBySearch(
          frequentlyUsedOptions,
          searchQuery,
        )
        return (
          <RetailerSelectorUncontrolled
            uploadType={selectedUploadType}
            options={filteredFrequentlyUsedOptions}
            disabled={disabled}
            selectedRetailers={selectedRetailers}
            setSelectedRetailers={setSelectedRetailers}
          />
        )
      }
      case RetailersFilterBy.Favorite: {
        const favoriteRetailerOptions = retailersToOptions(favoriteRetailers)
        const filteredFavoriteRetailerOptions = filterOptionsBySearch(
          favoriteRetailerOptions,
          searchQuery,
        )
        return (
          <RetailerSelectorUncontrolled
            uploadType={selectedUploadType}
            options={filteredFavoriteRetailerOptions}
            disabled={disabled}
            selectedRetailers={selectedRetailers}
            setSelectedRetailers={setSelectedRetailers}
          />
        )
      }
      case RetailersFilterBy.Custom: {
        const customRetailerOptions = retailersToOptions(customRetailers)
        const filteredCustomRetailerOptions = filterOptionsBySearch(
          customRetailerOptions,
          searchQuery,
        )
        return (
          <RetailerSelectorUncontrolled
            uploadType={selectedUploadType}
            options={filteredCustomRetailerOptions}
            disabled={disabled}
            isCustom
            selectedRetailers={selectedRetailers}
            setSelectedRetailers={setSelectedRetailers}
          />
        )
      }
      case RetailersFilterBy.AZ: {
        const AZRetailerOptions = retailersToOptions(allRetailers)
        const filtereAZRetailerOptions = filterOptionsBySearch(
          AZRetailerOptions,
          searchQuery,
        )
        return (
          <RetailerSelectorUncontrolled
            uploadType={selectedUploadType}
            options={filtereAZRetailerOptions}
            disabled={disabled}
            selectedRetailers={selectedRetailers}
            setSelectedRetailers={setSelectedRetailers}
          />
        )
      }
      case RetailersFilterBy.ZA: {
        const AZRetailerOptions = retailersToOptions(allRetailers)
        const filtereZARetailerOptions = filterOptionsBySearch(
          AZRetailerOptions,
          searchQuery,
        ).reverse()
        return (
          <RetailerSelectorUncontrolled
            uploadType={selectedUploadType}
            options={filtereZARetailerOptions}
            disabled={disabled}
            selectedRetailers={selectedRetailers}
            setSelectedRetailers={setSelectedRetailers}
          />
        )
      }
      default:
        return <></>
    }
  }

  const onSelectionCounterClick = () => {
    setModal(
      <RetailerSelectionModal
        selectedRetailers={selectedRetailers}
        setSelectedRetailers={setSelectedRetailers}
      />,
    )
  }

  const onWheel = () => {
    const newIsScrolled = elementRef.current?.scrollTop !== 0
    if (newIsScrolled !== isScrolled) {
      setIsScrolled(newIsScrolled)
    }

    const newIsScrolledToBottom =
      elementRef.current?.scrollHeight !==
      (elementRef.current?.scrollTop ?? 0) + (elementRef.current?.offsetHeight ?? 0)

    if (newIsScrolledToBottom !== isScrolledToBottom) {
      setIsScrolledToBottom(newIsScrolledToBottom)
    }
  }

  useEffect(() => {
    setIsLoading(true)
    const fetchData1 = dispatch.retailers.fetchAllRetailers()
    const fetchData2 = dispatch.retailers.fetchFrequentlyUsedRetailers(
      selectedAudit?.auditType || AuditType.Product,
    )
    const fetchData3 = dispatch.retailers.fetchCustomRetailers()
    const fetchData4 = dispatch.retailers.fetchFavoriteRetailers()
    Promise.all([fetchData1, fetchData2, fetchData3, fetchData4]).finally(() =>
      setIsLoading(false),
    )
  }, [])

  return (
    <>
      <div className="flex flex-col w-full mb-3 pt-3">
        <div className="mt-[30px] ">
          <span className="text-sub-headline text-gray-900 font-semibold">
            Select one or more Retailers you'd like this data to apply to
          </span>
        </div>
        <div className="flex">
          <div className="flex items-end justify-between w-full">
            <div className="flex items-end relative gap-5">
              <div className="mt-[25px] w-[500px]">
                <SearchInput
                  placeholder="Search"
                  onChange={onSearch}
                  className="w-full"
                  height={40}
                />
              </div>
              {selectedUploadType === UploadType.uploadMasterVersionContent && (
                <div
                  className={`flex h-10 items-center text-body font-medium pr-6 ${
                    selectedRetailers.length
                      ? "text-blue-600 hover:underline cursor-pointer"
                      : "text-gray-300"
                  }`}
                  onClick={selectedRetailers.length ? onSelectionCounterClick : undefined}
                >
                  {selectedRetailers.length} Retailers Selected
                </div>
              )}
            </div>
            <div className="flex items-end">
              <div style={{ width: 240 }}>
                <Select
                  label="Filter by"
                  options={retailersFilterByOptions}
                  value={filterBy}
                  onChange={(option: SelectOption) => {
                    const newOption = retailersFilterByOptions.find(
                      (el) => el.value === option.value,
                    )
                    setFilterBy(newOption?.value as RetailersFilterBy)
                  }}
                  controlHeight={40}
                />
              </div>
              {selectedUploadType === UploadType.uploadAsASource && (
                <div className="border-l border-gray-200 pl-[30px] ml-[30px] h-10">
                  <Button
                    variant="primary"
                    onClick={() => {
                      setModal(<SourceModal closeModal={unSetModal} />)
                    }}
                  >
                    Add New Source
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="relative" style={{ height: "calc(100% - 148px)" }}>
        {isScrolled && (
          <div
            className="absolute h-1 w-full top-0 bg-white z-10"
            style={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          />
        )}
        {isScrolledToBottom && (
          <div
            className="absolute bottom-0 w-full h-0.5"
            style={{
              boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.1)",
              borderRadius: "0px 0px 8px 8px",
            }}
          />
        )}
        <div
          className="h-full relative overflow-y-auto overflow-x-hidden w-full"
          ref={elementRef}
          onWheel={onWheel}
        >
          {isLoading ? (
            <Loader className="left-1/2 text-primary-" message="Loading..." />
          ) : (
            <>{getRetailerCards()}</>
          )}
        </div>
      </div>
    </>
  )
}
