import { getSampleFileApi } from "api/endpoints/data-mapping-import.api"
import { Button } from "components/button/button"
import { DragAndDropUploadZone } from "components/drag-and-drop-upload-zone/drag-and-drop-upload-zone"
import { Input } from "components/form-components/input/input"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { RootState } from "store"
import { downloadBase64Csv } from "utils"
import { LINK_CLASS_NAME } from "utils/constants"

import "./stepOne.scss"
import { Radio } from "components/form-components/radio/radio"
import { RetailerSelectorOption } from "common/types/RetailerSelectorOption.type"
import { UploadType } from "components/select-retailer-uncontrolled/select-retailer-uncontrolled"

type StepOneProps = {
  step: number
  setStep: (step: number) => void
  mapName: string
  setMapName: React.Dispatch<React.SetStateAction<string>>
  setSelectedRetailers: React.Dispatch<React.SetStateAction<RetailerSelectorOption[]>>
  selectedUploadType: UploadType
  setSelectedUploadType: React.Dispatch<React.SetStateAction<UploadType>>
  file: File | null
  setFile: React.Dispatch<React.SetStateAction<File | null>>
  onCancel: () => void
}

export const StepOne = ({
  step,
  setStep,
  mapName,
  setMapName,
  setSelectedRetailers,
  selectedUploadType,
  setSelectedUploadType,
  file,
  setFile,
  onCancel,
}: StepOneProps) => {
  const selectedWorkspaceId = useSelector(
    (state: RootState) => state.workspaces.selectedWorkspaceId,
  )

  const navigate = useNavigate()

  const isNextDisabled = file === null || mapName === ""

  const onMapNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMapName(event.target.value)
  }

  return (
    <div className="flex flex-col h-full">
      <div className="step-body">
        <div className="flex justify-between pb-3 border-b border-gray-200 mb-24">
          <div className="flex flex-col">
            <div className="text-title-3 font-bold text-gray-900 pb-[5px]">Details</div>
            <div className="text-card text-gray-500 font-medium">
              Add a map name and upload the CSV file.
            </div>
          </div>
          <Button variant="outlined" className="self-end" onClick={onCancel}>
            Cancel Upload
          </Button>
        </div>
        <div className="flex mx-auto max-w-[1400px]">
          <div className="flex-grow mr-10">
            <div className="flex">
              <div className="step-order">1</div>
              <div>
                <div className="text-sub-headline text-gray-900 font-semibold pb-[5px]">
                  Name
                </div>
                <div className="text-sub-headline text-gray-500 font-medium pb-3">
                  Please enter the name of the map you want to create.
                </div>
                <Input
                  className="max-w-[422px] h-10 mb-8"
                  placeholder="Type here..."
                  value={mapName}
                  onChange={onMapNameChange}
                />
              </div>
            </div>
            <div className="flex">
              <div className="step-order">2</div>
              <div>
                <div className="text-sub-headline text-gray-900 font-semibold pb-[5px]">
                  Upload Type
                </div>
                <div className="text-footnote text-gray-500">
                  <p className="pb-3">
                    <span className="text-gray-900">Upload data as Master Version</span>
                    <span>
                      {" "}
                      - Use this functionality to upload data and associate it with an
                      existing data source (retailer integration). Use this to compare
                      what is live vs. what you may have in the PIM (or a master/golden
                      record data source).
                    </span>
                  </p>

                  <p className="pb-6">
                    <span className="text-gray-900">Upload data as Source</span>
                    <span>
                      {" "}
                      - Use this functionality to upload direct master source/record data
                      against an existing or new data source. If a data source doesn't
                      exist, select "Add New Source" and follow the workflow to create a
                      new data source for our system to evaluate and analyze.
                    </span>
                  </p>
                </div>
                <div className="flex gap-5 items-end pb-2">
                  <Radio
                    label="Upload Master Version Content"
                    onChange={() => {
                      setSelectedRetailers([])
                      setSelectedUploadType(UploadType.uploadMasterVersionContent)
                    }}
                    checked={selectedUploadType === UploadType.uploadMasterVersionContent}
                  />
                  <Radio
                    label="Upload as a Source"
                    onChange={() => {
                      setSelectedRetailers([])
                      setSelectedUploadType(UploadType.uploadAsASource)
                    }}
                    checked={selectedUploadType === UploadType.uploadAsASource}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex pl-10 border-l border-gray-200">
            <div className="step-order">3</div>

            <div>
              <div className="text-sub-headline text-gray-900 font-semibold pb-[5px]">
                Upload File
              </div>
              <div className="text-sub-headline text-gray-500 font-medium pb-3">
                Please upload the file you want to map from.
              </div>
              <DragAndDropUploadZone
                className="w-[422px] h-[250px] border border-dashed border-blue-600 rounded-lg bg-gray-50"
                file={file}
                setFile={setFile}
                description="Accepted file types: CSV"
                accept={{
                  "text/csv": [".csv"],
                }}
                acceptTypesArray={["text/csv"]}
                formatError="Invalid file type! Only CSV files are accepted for this upload."
                removeFile={() => setFile(null)}
              />
              <div className="text-caption-1 text-gray-500 flex pt-3">
                <div> Don’t know how to prepare the file? &nbsp;</div>
                <div
                  className={LINK_CLASS_NAME}
                  onClick={() => {
                    if (selectedWorkspaceId) {
                      getSampleFileApi(selectedWorkspaceId).then(downloadBase64Csv)
                    }
                  }}
                >
                  Get sample here!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="step-button-bar">
        <Button
          variant="outlined"
          onClick={() => {
            navigate(`/main/workspaces/${selectedWorkspaceId}/edit/upload`, {
              state: 1,
            })
          }}
          className="mr-3"
        >
          Back
        </Button>
        <Button
          variant="primary"
          disabled={isNextDisabled}
          onClick={() => {
            setStep(step + 1)
          }}
        >
          Next Step
        </Button>
      </div>
    </div>
  )
}
