import React from "react"
import { FC, ReactNode, useEffect, useRef, useState } from "react"
import Tippy from "@tippyjs/react"
import useResizeObserver from "@react-hook/resize-observer"
import { Button } from "components/button/button"
import { Icon, ICONS } from "components/icon/icon"
import "./filter-category.scss"

type Props = {
  title: string
  children: ReactNode
  hasSearch?: boolean
  isInfoPointVisible?: boolean
}

const INITIAL_HEIGHT = 210

export const FilterCategory: FC<Props> = ({
  title,
  children,
  isInfoPointVisible,
}: Props) => {
  const [collapsed, setCollapsed] = useState(true)
  const [longBody, setLongBody] = useState(false)
  const ref = useRef(null)

  const getMaxHeight = (collapsed: boolean, title: string) => {
    if (collapsed) {
      return title === "Custom Fields" ? "200px" : `${INITIAL_HEIGHT}px`
    }
    return "none"
  }

  const toggle = () => {
    setCollapsed(!collapsed)
    if (ref.current && !collapsed) {
      ;(ref.current as any)?.scrollTo(0, 0)
    }
  }

  const useSize = (target: any) => {
    const [size, setSize] = useState(0)

    React.useLayoutEffect(() => {
      setSize(target?.current?.getBoundingClientRect())
    }, [target])

    useResizeObserver(target, (entry) => setSize(entry?.target?.scrollHeight))

    return size
  }

  const filterListSize = useSize(ref)

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { collapsed })
    }
    return child
  })

  useEffect(() => {
    setLongBody(INITIAL_HEIGHT < filterListSize)
  }, [filterListSize])

  return (
    <div className="filter-category">
      <div className="category-title flex items-center justify-between">
        {title}
        {isInfoPointVisible && (
          <Tippy
            interactive
            placement="bottom-end"
            content={
              <div className="flex flex-col gap-2 bg-white h-[116px] w-[220px] p-4 shadow-lg">
                <div className="text-caption-1">Custom Fields</div>
                <div className="text-caption-1 text-gray-500 font-normal">
                  When active, each item must have at least one value selected. For this
                  reason, you cannot deselect them all from the dropdown.
                </div>
              </div>
            }
          >
            <span>
              <Icon icon={ICONS.INFORMATION_CIRCLE_OUTLINED} />
            </span>
          </Tippy>
        )}
      </div>
      <div
        ref={ref}
        className={`category-body ${collapsed ? "collapsed" : ""}`}
        style={{
          maxHeight: getMaxHeight(collapsed, title),
          overflowY: collapsed ? "hidden" : "auto",
        }}
      >
        {childrenWithProps}
      </div>
      {longBody && (
        <Button onClick={toggle} variant="link" className="secondary">
          {collapsed ? (
            <div className="flex items-center">
              <Icon icon={ICONS.CHEVRON_DOWN} /> Show all
            </div>
          ) : (
            <div className="flex items-center">
              <Icon icon={ICONS.CHEVRON_UP} /> Show less
            </div>
          )}
        </Button>
      )}
    </div>
  )
}
