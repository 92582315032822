import { FC, useEffect, useState } from "react"

import { CustomFieldFilterModel } from "common/models/CustomFieldFilterModel"
import { CustomFieldValue } from "common/models/CustomFieldValue"
import { ItemFilterModel } from "common/models/ItemFilterModel"
import { Checkbox } from "components/form-components/checkbox/checkbox"
import { FilterSearch } from "../filter-search/filter-search"
import { CustomSelect } from "components/custom-select/custom-select"

import "./custom-field-model-options.scss"

type Props = {
  options?: CustomFieldFilterModel[]
  filters: ItemFilterModel
  setFilters: (filters: ItemFilterModel) => void
  collapsed?: boolean
}

export const CustomFieldModelOption: FC<Props> = ({
  options = [],
  filters,
  setFilters,
  collapsed,
}: Props) => {
  const [currentFilter, setCurrentFilter] = useState([] as CustomFieldValue[])
  const [filteredOptions, setFilteredOptions] = useState(options.slice(0, 6))
  const [searchValue, setSearchValue] = useState("")

  useEffect(() => {
    setCurrentFilter(filters.customFields as CustomFieldValue[])
  }, [filters])

  useEffect(() => {
    if (collapsed) {
      return
    }

    if (searchValue) {
      setFilteredOptions(filteredOptions)
    } else {
      setFilteredOptions(options)
    }
  }, [collapsed, searchValue])

  const setValue = (item: CustomFieldValue) => {
    const currentIndex = currentFilter.findIndex((filter) => filter.key === item.key)
    let customFields = [...currentFilter]

    if (currentIndex > -1) {
      if (item.values?.length === 0) {
        customFields = currentFilter.filter((filter) => filter.key !== item.key)
      } else {
        customFields[currentIndex] = item
      }
    } else {
      customFields.push(item)
    }

    setCurrentFilter(customFields)
    setFilters({
      ...filters,
      customFields,
    })
  }

  return (
    <div className="string-model-options">
      <FilterSearch
        options={options}
        setOptions={setFilteredOptions}
        value={searchValue}
        setSearchValue={setSearchValue}
        compareProp="key"
      />
      {filteredOptions.map((item: CustomFieldFilterModel, index: number) => {
        const currentFieldValue = currentFilter.find((f) => f.key === item.key)
        return (
          <div className="flex items-center">
            <Checkbox
              value={currentFieldValue === undefined ? false : true}
              key={index}
              label={item.key}
              small
              onChange={() =>
                setValue(
                  currentFieldValue
                    ? { key: item.key, values: [] }
                    : { key: item.key, values: [item.values[0]] },
                )
              }
            />
            <div className="grid grid-flow-col justify-end gap-x-1">
              <CustomSelect
                options={item.values?.map((val) => ({ label: val, value: val }))}
                isDisabled={!currentFieldValue}
                selectedOptions={
                  currentFieldValue?.values?.map((value) => ({ label: value, value })) ??
                  []
                }
                setSelectedOptions={(newValues) => {
                  setValue({
                    key: item.key,
                    values: newValues.map((option) => option.value),
                  })
                }}
              />
            </div>
          </div>
        )
      })}
    </div>
  )
}
