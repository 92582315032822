import { ScoreFilterModel } from "./ScoreFilterModel"
import { AlertFilterModel } from "./AlertFilterModel"
import { CustomFieldValue } from "./CustomFieldValue"

export class ItemFilterModel {
  public searchValue?: string = ""
  public dataSource?: string[] = []
  public jobNames?: string[] = []
  public jobInstanceIds?: string[] = []
  public brands?: string[] = []
  public fulfillmentType?: string[] = []
  public isPrime?: boolean = false
  public category?: string[] = []
  public firstSubCategory?: string[] = []
  public secondSubCategory?: string[] = []
  public hasPrimaryKeywords?: boolean = false
  public hasSecondaryKeywords?: boolean = false
  public has360Spins?: boolean = false
  public hasEnhancedContent?: boolean = false
  public tags?: string[] = []
  public scoreFilters?: ScoreFilterModel[] = []
  public alertFilterModels?: AlertFilterModel[] = []
  public customFields?: CustomFieldValue[] = []
  constructor(obj = {} as any) {
    obj = obj || {}
    this.searchValue = obj.searchValue === null ? "" : obj.searchValue
    this.dataSource = obj.dataSource || []
    this.jobNames = obj.jobNames || []
    this.jobInstanceIds = obj.jobInstanceIds || []
    this.brands = obj.brands || []
    this.fulfillmentType = obj.fulfillmentType || []
    this.isPrime = obj.isPrime === null ? false : obj.isPrime
    this.category = obj.category || []
    this.firstSubCategory = obj.firstSubCategory || []
    this.secondSubCategory = obj.secondSubCategory || []
    this.hasPrimaryKeywords =
      obj.hasPrimaryKeywords === null ? false : obj.hasPrimaryKeywords
    this.hasSecondaryKeywords =
      obj.hasSecondaryKeywords === null ? false : obj.hasSecondaryKeywords
    this.has360Spins = obj.has360Spins === null ? false : obj.has360Spins
    this.hasEnhancedContent =
      obj.hasEnhancedContent === null ? false : obj.hasEnhancedContent
    this.tags = obj.tags || []
    this.scoreFilters =
      obj.scoreFilters
        ?.filter((item: any) => item !== undefined)
        .map((item: any) => new ScoreFilterModel(item)) || []
    this.alertFilterModels =
      obj.alertFilterModels
        ?.filter((item: any) => item !== undefined)
        .map((item: any) => new AlertFilterModel(item)) || []
    this.customFields =
      obj.customFields
        ?.filter((item: any) => item !== undefined)
        .map((item: any) => {
          return new CustomFieldValue({
            key: item.key,
            values: Array.isArray(item.values)
              ? item.values
              : item.value
              ? [item.value]
              : [],
          })
        }) || []
  }
}
