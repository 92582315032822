import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootDispatch, RootState } from "store"
import { RetailerSelector } from "./retailer-selector/retailer-selector"
import { SearchInput } from "components/form-components/search-input/search-input"
import { Select, SelectOption } from "components/form-components/select/select"
import { filterOptionsBySearch, retailersToOptions } from "utils"
import { AuditType } from "common/enums/AuditType.enum"
import { Loader } from "components/loader/loader"
import { retailersFilterByOptions } from "utils/constants"
import { RetailersFilterBy } from "common/enums/RetailersFilterBy.enum"
import { RetailerSelectorOption } from "common/types/RetailerSelectorOption.type"
import { CheckAndClear } from "./check-and-clear/check-and-clear"
import { useFormContext } from "react-hook-form"
import { useModal } from "context/modal-context"
import { RetailerSelectionModal } from "components/select-retailer-uncontrolled/retailer-selection-modal"

type Props = {
  name: string
  disabled?: boolean
}

export const SelectRetailer = ({ name, disabled = false }: Props) => {
  const [searchQuery, setSearchQuery] = useState("")
  const [isScrolled, setIsScrolled] = useState(false)
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [filterBy, setFilterBy] = useState(RetailersFilterBy.FrequentAndOthers)
  const dispatch = useDispatch<RootDispatch>()
  const elementRef = useRef<HTMLDivElement>(null)
  const allRetailers = useSelector((state: RootState) => state.retailers.allRetailers)
  const frequentlyUsedRetailers = useSelector(
    (state: RootState) => state.retailers.frequentlyUsedRetailers,
  )
  const favoriteRetailers = useSelector(
    (state: RootState) => state.retailers.favoriteRetailers,
  )
  const customRetailers = useSelector(
    (state: RootState) => state.retailers.customRetailers,
  )
  const selectedAudit = useSelector((state: RootState) => state.audits.selectedAudit)

  const { setModal } = useModal()

  const { watch, setValue } = useFormContext()
  const selectedRetailers = watch(name)

  const filterOptions =
    name === "availableFor"
      ? retailersFilterByOptions
      : retailersFilterByOptions.filter(
          (filter) => filter.value !== RetailersFilterBy.Custom,
        )

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value?.toLowerCase())
  }

  const setFormSelectedRetailers = (newRetailerList: RetailerSelectorOption[]) => {
    setValue("availableFor", newRetailerList, {
      shouldDirty: true,
      shouldValidate: true,
    })
  }

  const onSelectionCounterClick = () => {
    setModal(
      <RetailerSelectionModal
        selectedRetailers={selectedRetailers}
        setSelectedRetailers={setFormSelectedRetailers}
      />,
    )
  }

  const getRetailerCards = (): JSX.Element => {
    switch (filterBy) {
      case RetailersFilterBy.FrequentAndOthers: {
        const frequentlyUsedOptions = retailersToOptions(frequentlyUsedRetailers)
        const filteredFrequentlyUsedOptions = filterOptionsBySearch(
          frequentlyUsedOptions,
          searchQuery,
        )
        const frequentRetailersIdsSet = new Set(
          frequentlyUsedRetailers.map((retailer) => retailer.id),
        )
        const otherRetailers = allRetailers.filter(
          (retailer) => !frequentRetailersIdsSet.has(retailer.id),
        )
        const otherRetailerOptions = retailersToOptions(otherRetailers)
        const filteredOtherRetailers = filterOptionsBySearch(
          otherRetailerOptions,
          searchQuery,
        )
        return (
          <div>
            <p className="text-callout font-semibold mb-3">Frequently used</p>
            <RetailerSelector
              name={name}
              options={filteredFrequentlyUsedOptions}
              required
              disabled={disabled}
            />
            <p className="text-callout font-semibold my-3">Others</p>
            <RetailerSelector
              name={name}
              options={filteredOtherRetailers}
              required
              disabled={disabled}
            />
          </div>
        )
      }
      case RetailersFilterBy.FrequentlyUsed: {
        const frequentlyUsedOptions = retailersToOptions(frequentlyUsedRetailers)
        const filteredFrequentlyUsedOptions = filterOptionsBySearch(
          frequentlyUsedOptions,
          searchQuery,
        )
        return (
          <RetailerSelector
            name={name}
            options={filteredFrequentlyUsedOptions}
            required
            disabled={disabled}
          />
        )
      }
      case RetailersFilterBy.Favorite: {
        const favoriteRetailerOptions = retailersToOptions(favoriteRetailers)
        const filteredFavoriteRetailerOptions = filterOptionsBySearch(
          favoriteRetailerOptions,
          searchQuery,
        )
        return (
          <RetailerSelector
            name={name}
            options={filteredFavoriteRetailerOptions}
            required
            disabled={disabled}
          />
        )
      }
      case RetailersFilterBy.Custom: {
        const customRetailerOptions = retailersToOptions(customRetailers)
        const filteredCustomRetailerOptions = filterOptionsBySearch(
          customRetailerOptions,
          searchQuery,
        )
        return (
          <RetailerSelector
            name={name}
            options={filteredCustomRetailerOptions}
            required
            disabled={disabled}
          />
        )
      }
      case RetailersFilterBy.AZ: {
        const AZRetailerOptions = retailersToOptions(allRetailers)
        const filtereAZRetailerOptions = filterOptionsBySearch(
          AZRetailerOptions,
          searchQuery,
        )
        return (
          <RetailerSelector
            name={name}
            options={filtereAZRetailerOptions}
            required
            disabled={disabled}
          />
        )
      }
      case RetailersFilterBy.ZA: {
        const AZRetailerOptions = retailersToOptions(allRetailers)
        const filtereZARetailerOptions = filterOptionsBySearch(
          AZRetailerOptions,
          searchQuery,
        ).reverse()
        return (
          <RetailerSelector
            name={name}
            options={filtereZARetailerOptions}
            required
            disabled={disabled}
          />
        )
      }
      default:
        return <></>
    }
  }

  const onWheel = () => {
    const newIsScrolled = elementRef.current?.scrollTop !== 0
    if (newIsScrolled !== isScrolled) {
      setIsScrolled(newIsScrolled)
    }

    const newIsScrolledToBottom =
      elementRef.current?.scrollHeight !==
      (elementRef.current?.scrollTop ?? 0) + (elementRef.current?.offsetHeight ?? 0)

    if (newIsScrolledToBottom !== isScrolledToBottom) {
      setIsScrolledToBottom(newIsScrolledToBottom)
    }
  }

  useEffect(() => {
    setIsLoading(true)
    const fetchData1 =
      name === "availableFor"
        ? dispatch.retailers.fetchAllRetailers()
        : dispatch.retailers.fetchAllRetailersByAuditType(
            selectedAudit?.auditType || AuditType.Product,
          )
    const fetchData2 = dispatch.retailers.fetchFrequentlyUsedRetailers(
      selectedAudit?.auditType || AuditType.Product,
    )
    const fetchData3 = dispatch.retailers.fetchCustomRetailers()
    const fetchData4 = dispatch.retailers.fetchFavoriteRetailers()
    Promise.all([fetchData1, fetchData2, fetchData3, fetchData4]).finally(() =>
      setIsLoading(false),
    )
  }, [])

  return (
    <>
      <div className="flex w-full my-3 justify-between">
        <div className="flex gap-3 items-end">
          <div className="w-[500px]">
            <SearchInput
              placeholder="Search"
              onChange={onSearch}
              className="w-full"
              height={48}
            />
          </div>
          {name === "availableFor" && (
            <div
              className={`flex h-10 items-center text-body font-medium pr-6 whitespace-nowrap ${
                selectedRetailers?.length
                  ? "text-blue-600 hover:underline cursor-pointer"
                  : "text-gray-300"
              }`}
              onClick={selectedRetailers?.length ? onSelectionCounterClick : undefined}
            >
              {selectedRetailers?.length} Retailers Selected
            </div>
          )}
        </div>
        <div className="flex gap-3 items-end">
          <div style={{ width: 300 }}>
            <Select
              label="Filter by"
              options={filterOptions}
              value={filterBy}
              onChange={(option: SelectOption) => {
                const newOption = retailersFilterByOptions.find(
                  (el) => el.value === option.value,
                )
                setFilterBy(newOption?.value as RetailersFilterBy)
              }}
            />
          </div>
          {name === "availableFor" && (
            <CheckAndClear
              disabled={disabled}
              filterBy={filterBy}
              searchQuery={searchQuery}
            />
          )}
        </div>
      </div>
      <div
        className="h-full overflow-y-auto overflow-x-hidden py-5 w-full"
        ref={elementRef}
        onWheel={onWheel}
      >
        {isScrolled && (
          <div
            className="absolute h-1 w-full -mt-6 bg-white"
            style={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              width: name === "availableFor" ? undefined : "calc(100% - 40px)",
            }}
          />
        )}

        {isLoading ? (
          <Loader className="left-1/2 text-primary-" message="Loading..." />
        ) : (
          <>{getRetailerCards()}</>
        )}
        {isScrolledToBottom && name === "availableFor" && (
          <div
            className="absolute bottom-0 -mb-[85px] w-full h-0.5"
            style={{
              boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.1)",
              borderRadius: "0px 0px 8px 8px",
            }}
          />
        )}
      </div>
    </>
  )
}
