import React, { CSSProperties, ReactNode, useState } from "react"
import { Menu } from "@headlessui/react"
import { Checkbox } from "components/form-components/checkbox/checkbox"
import { Icon, ICONS } from "components/icon/icon"
import { Dropdown } from "components/dropdown/dropdown"
import "./new-audit-card.scss"

type Props = {
  className?: string
  title: string | ReactNode
  body: string | string[] | ReactNode
  selected?: boolean
  disabled?: boolean
  checkbox?: boolean
  onClick?: () => void
  style?: CSSProperties
  isThreeDots?: boolean
  handleEdit?: () => void
  handleDelete?: () => void
}

export const NewAuditCard: React.FC<Props> = ({
  className = "",
  title = "",
  body = "",
  selected = false,
  disabled = false,
  checkbox = false,
  onClick,
  style = {},
  isThreeDots = false,
  handleEdit,
  handleDelete,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  return (
    <div
      className={`new-audit-card${selected ? " selected" : ""}${
        disabled ? " disabled" : ""
      } ${className}`}
      onClick={
        typeof onClick === "function" && !disabled && !isDropdownOpen
          ? onClick
          : undefined
      }
      style={style}
    >
      <Checkbox
        readonly
        value={selected}
        disabled={disabled}
        className={`-my-4 ml-[90%] z-[1] pointer-events-none ${
          checkbox ? "" : "invisible"
        }`}
      />
      {isThreeDots && (
        <Dropdown
          style={{
            borderRadius: "8px",
            height: "92px",
            width: "100px",
            zIndex: 10,
            marginTop: "20px",
            marginRight: "-20px",
          }}
          trigger={
            <Menu.Button
              className="ml-[93%] -my-4 mt-[-10px] hover:bg-gray-100 h-[26px] w-[26px] rounded-full flex justify-center items-center"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              <Icon icon={ICONS.KEBAB_MENU} />
            </Menu.Button>
          }
        >
          <div className="flex flex-col z-10 justify-center items-start">
            <Menu.Item>
              <div
                className="p-[10px] hover:bg-gray-100 w-full"
                onClick={() => {
                  handleEdit && handleEdit()
                  setIsDropdownOpen(false)
                }}
              >
                Edit
              </div>
            </Menu.Item>
            <Menu.Item>
              <div
                className="text-red-600 p-[10px] hover:bg-gray-100 w-full"
                onClick={() => {
                  handleDelete && handleDelete()
                  setIsDropdownOpen(false)
                }}
              >
                Delete
              </div>
            </Menu.Item>
          </div>
        </Dropdown>
      )}
      {React.isValidElement(title) && title}
      {typeof title === "string" && <div className="new-audit-card-title">{title}</div>}
      <hr />
      <div className="new-audit-card-body">
        {React.isValidElement(body) && body}
        {Array.isArray(body) &&
          body?.map((i) => <span className="new-audit-card-description">{i}</span>)}
        {typeof body === "string" && (
          <span className="new-audit-card-description">{body}</span>
        )}
      </div>
    </div>
  )
}
