import { RetailersFilterBy } from "common/enums/RetailersFilterBy.enum"
import { RetailerSelectorOption } from "common/types/RetailerSelectorOption.type"
import { Button } from "components/button/button"
import { useFormContext } from "react-hook-form"
import { useSelector } from "react-redux"
import { RootState } from "store"
import { filterOptionsBySearch, retailersToOptions } from "utils"

type Props = {
  disabled: boolean
  filterBy: RetailersFilterBy
  searchQuery: string
}

export const CheckAndClear = ({ disabled, filterBy, searchQuery }: Props) => {
  const { setValue, getValues } = useFormContext()

  const allRetailers = useSelector((state: RootState) => state.retailers.allRetailers)
  const frequentlyUsedRetailers = useSelector(
    (state: RootState) => state.retailers.frequentlyUsedRetailers,
  )
  const favoriteRetailers = useSelector(
    (state: RootState) => state.retailers.favoriteRetailers,
  )
  const customRetailers = useSelector(
    (state: RootState) => state.retailers.customRetailers,
  )

  let currentRetailers = []
  switch (filterBy) {
    case RetailersFilterBy.FrequentlyUsed:
      currentRetailers = [...frequentlyUsedRetailers]
      break
    case RetailersFilterBy.Favorite:
      currentRetailers = [...favoriteRetailers]
      break
    case RetailersFilterBy.Custom:
      currentRetailers = [...customRetailers]
      break
    default:
      currentRetailers = [...allRetailers]
      break
  }
  const currentRetailersOptions = retailersToOptions(currentRetailers)

  const filteredCurrentRetailers = filterOptionsBySearch(
    currentRetailersOptions,
    searchQuery,
  )

  const isSubset = (arr1: RetailerSelectorOption[], arr2: RetailerSelectorOption[]) =>
    arr1.every((el) => arr2.find((el2: RetailerSelectorOption) => el.value === el2.value))

  const selectedRetailers = getValues("availableFor")
  const isCheckAllDisabled =
    disabled || isSubset(filteredCurrentRetailers, selectedRetailers)

  const isClearDisabled =
    disabled ||
    filteredCurrentRetailers.every(
      (retailer) =>
        !selectedRetailers.some(
          (selectedRetailer: RetailerSelectorOption) =>
            retailer.value === selectedRetailer.value,
        ),
    )

  const handleCheckAll = () => {
    const invisibleSelectedRetailers = selectedRetailers.filter(
      (retailer: RetailerSelectorOption) =>
        !filteredCurrentRetailers.find(
          (currentRetailer) => currentRetailer.value === retailer.value,
        ),
    )
    const updatedSelection = [...invisibleSelectedRetailers, ...filteredCurrentRetailers]
    setValue("availableFor", updatedSelection, {
      shouldDirty: true,
      shouldValidate: true,
    })
  }

  const handleClear = () => {
    const invisibleSelectedRetailers = selectedRetailers.filter(
      (retailer: RetailerSelectorOption) =>
        !filteredCurrentRetailers.find(
          (currentRetailer) => currentRetailer.value === retailer.value,
        ),
    )
    setValue("availableFor", invisibleSelectedRetailers, {
      shouldDirty: true,
      shouldValidate: true,
    })
  }
  // const viewAllFilteredRetailers = [...filteredFrequentlyUsed, ...filteredRetailerOptions]
  // const disabledCheckAll =
  //   disabled ||
  //   (filterBy === RetailersFilterBy.FrequentlyUsed
  //     ? isSubset(filteredFrequentlyUsed, selectedRetailers)
  //     : filterBy === RetailersFilterBy.AZ
  //     ? isSubset(filteredRetailerOptions, selectedRetailers)
  //     : isSubset(viewAllFilteredRetailers, selectedRetailers))

  // const disabledClear =
  //   disabled ||
  //   (filterBy === RetailersFilterBy.FrequentlyUsed
  //     ? filteredFrequentlyUsed.filter((el) =>
  //         selectedRetailers.find(
  //           (option: RetailerSelectorOption) => option.value === el.value,
  //         ),
  //       ).length === 0
  //     : filterBy === RetailersFilterBy.AZ
  //     ? filteredRetailerOptions.filter((el) =>
  //         selectedRetailers.find(
  //           (option: RetailerSelectorOption) => option.value === el.value,
  //         ),
  //       ).length === 0
  //     : viewAllFilteredRetailers.filter((el) =>
  //         selectedRetailers.find(
  //           (option: RetailerSelectorOption) => option.value === el.value,
  //         ),
  //       ).length === 0)

  // const handleCheckAll = () => {
  //   const updatedRetailers =
  //     filterBy === RetailersFilterBy.FrequentlyUsed
  //       ? selectedRetailers.concat(filteredFrequentlyUsed)
  //       : filterBy === RetailersFilterBy.AZ
  //       ? selectedRetailers.concat(filteredRetailerOptions)
  //       : selectedRetailers.concat(viewAllFilteredRetailers)

  //   setValue("availableFor", updatedRetailers, {
  //     shouldDirty: true,
  //     shouldValidate: true,
  //   })
  // }

  // const handleClear = () => {
  //   const updatedRetailers =
  //     filterBy === RetailersFilterBy.FrequentlyUsed
  //       ? selectedRetailers?.filter(
  //           (el: RetailerSelectorOption) =>
  //             !filteredFrequentlyUsed.find((option) => option.value === el.value),
  //         )
  //       : filterBy === RetailersFilterBy.AZ
  //       ? selectedRetailers?.filter(
  //           (el: RetailerSelectorOption) =>
  //             !filteredRetailerOptions.find((option) => option.value === el.value),
  //         )
  //       : selectedRetailers?.filter(
  //           (el: RetailerSelectorOption) =>
  //             !viewAllFilteredRetailers.find((option) => option.value === el.value),
  //         )

  //   setValue("availableFor", updatedRetailers, {
  //     shouldDirty: true,
  //     shouldValidate: true,
  //   })
  // }

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleCheckAll}
        disabled={isCheckAllDisabled}
        className="mb-1"
      >
        Check All
      </Button>
      <Button
        variant="outlined"
        onClick={handleClear}
        disabled={isClearDisabled}
        className="mb-1"
      >
        Clear
      </Button>
    </>
  )
}
