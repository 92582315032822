import { UserAllocatedWorkspaceModel } from 'common/models/UserAllocatedWorkspaceModel';
import { WorkspaceCreateModel } from 'common/models/WorkspaceCreateModel';
import { WorkspaceModel } from 'common/models/WorkspaceModel';
import { WorkspaceDashboardModel } from 'common/models/WorkspaceDashboardModel';
import { WorkspaceAllocatedUserModel } from 'common/models/WorkspaceAllocatedUserModel';
import { UpdateMemberRoleModel } from 'common/models/UpdateMemberRoleModel';
import { WorkspaceTagsModel } from 'common/models/WorkspaceTagsModel';
import { API } from "../api";
import { RetailerModel } from 'common/models/RetailerModel';

// provide searchValue if present
export const getUserWorkspacesApi = (searchValue: string): Promise<UserAllocatedWorkspaceModel[]> => API().get(`workspaces${searchValue ? "?searchValue=" + searchValue : ""}`)

export const createWorkspaceApi = (body: WorkspaceCreateModel): Promise<WorkspaceModel> => API().post(`workspaces`, body);

export const getWorkspaceDashboardApi = (workspaceId: string): Promise<WorkspaceDashboardModel> => API().get(`workspaces/${workspaceId}/dashboard`);

export const updateWorkspaceApi = (workspaceId: string, body: WorkspaceModel): Promise<WorkspaceModel> => API().put(`workspaces/${workspaceId}`, body);

export const deleteWorkspaceApi = (workspaceId: string): Promise<boolean> => API().delete(`workspaces/${workspaceId}`);

export const updateDefaultWorkspaceApi = (workspaceId: string): Promise<boolean> => API().put(`workspaces/${workspaceId}/setdefault`);

export const getWorkspaceMembersApi = (workspaceId: string): Promise<WorkspaceAllocatedUserModel[]> => API().get(`workspaces/${workspaceId}/members`);

export const deleteMemberWorkspaceApi = (workspaceId: string, userId: string): Promise<boolean> => API().delete(`workspaces/${workspaceId}/members/${userId}/remove`);

export const updateMemberRoleApi = (workspaceId: string, body: UpdateMemberRoleModel): Promise<boolean> => API().put(`workspaces/${workspaceId}/members/updateRole`, body);

export const updateWorkspaceTagsApi = (workspaceId: string, body: string[]): Promise<boolean> => API().put(`workspaces/${workspaceId}/tags`, body);

export const getWorkspaceTagsApi = (workspaceId: string): Promise<WorkspaceTagsModel> => API().get(`workspaces/${workspaceId}/tags`);

export const addWorkspaceLogoApi = (workspaceId: string, body: any): Promise<boolean> => API().put(`workspaces/${workspaceId}/logo`, body);

export const removeWorkspaceLogoApi = (workspaceId: string): Promise<boolean> => API().put(`workspaces/${workspaceId}/logo/remove`);

export const getFavoriteRetailersApi = (workspaceId: string): Promise<RetailerModel[]> => API().get(`workspaces/${workspaceId}/favoriteRetailers`);

export const updateFavoriteRetailersApi = (workspaceId: string, body: string[]): Promise<RetailerModel[]> => API().put(`workspaces/${workspaceId}/favoriteRetailers`, body);
