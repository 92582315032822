import { RetailerSelectorOption } from "common/types/RetailerSelectorOption.type"
import { Button } from "components/button/button"
import { SearchInput } from "components/form-components/search-input/search-input"
import { RetailerSelectorUncontrolled } from "components/select-retailer/retailer-selector-uncontrolled"
import { useState } from "react"
import { UploadType } from "./select-retailer-uncontrolled"
import { filterOptionsBySearch } from "utils"
import { useModal } from "context/modal-context"

export const RetailerSelectionModal = ({
  selectedRetailers,
  setSelectedRetailers,
}: {
  selectedRetailers: RetailerSelectorOption[]
  setSelectedRetailers: (newSelection: RetailerSelectorOption[]) => void
}) => {
  const [searchString, setSearchString] = useState("")
  const [newSelection, setNewSelection] = useState(selectedRetailers)

  const { unSetModal } = useModal()

  const options = filterOptionsBySearch(selectedRetailers, searchString)

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value?.toLowerCase())
  }

  const onClearClick = () => {
    setNewSelection([])
  }

  const onCancelClick = () => {
    unSetModal()
  }

  const onSaveClick = () => {
    setSelectedRetailers(newSelection)
    unSetModal()
  }

  return (
    <div className="w-[868px]">
      <div className="text-title-3 font-bold text-gray-900 mb-3">
        {selectedRetailers.length} Retailers Selected
      </div>
      <div className="text-body text-gray-500 font-medium mb-5">
        This is the list with all selected retailers
      </div>
      <div className="flex gap-3 mb-5">
        <SearchInput
          placeholder="Search"
          onChange={onSearch}
          className="w-full"
          height={40}
        />
        <Button variant="outlined" onClick={onClearClick}>
          Clear
        </Button>
      </div>
      <div className="h-96 overflow-y-auto overflow-x-hidden">
        <RetailerSelectorUncontrolled
          uploadType={UploadType.uploadMasterVersionContent}
          options={options}
          selectedRetailers={newSelection}
          setSelectedRetailers={setNewSelection}
        />
      </div>
      <div className="flex justify-between mt-[50px]">
        <Button variant="outlined" onClick={onCancelClick}>
          Cancel
        </Button>
        <Button
          variant="success"
          disabled={newSelection.length === selectedRetailers.length}
          onClick={onSaveClick}
        >
          Save
        </Button>
      </div>
    </div>
  )
}
