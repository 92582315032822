import { Button } from "components/button/button"
import {
  SelectRetailerUncontrolled,
  UploadType,
} from "components/select-retailer-uncontrolled/select-retailer-uncontrolled"
import { RetailerSelectorOption } from "common/types/RetailerSelectorOption.type"
import Tippy from "@tippyjs/react"
import { Icon, ICONS } from "components/icon/icon"

type StepTwoProps = {
  step: number
  setStep: (step: number) => void
  selectedRetailers: RetailerSelectorOption[]
  setSelectedRetailers: React.Dispatch<React.SetStateAction<RetailerSelectorOption[]>>
  selectedUploadType: UploadType
  importDataMapping: () => void
  onCancel: () => void
}

export const StepTwo = ({
  step,
  setStep,
  selectedRetailers,
  setSelectedRetailers,
  selectedUploadType,
  importDataMapping,
  onCancel,
}: StepTwoProps) => {
  const isNextStepDisabled = selectedRetailers.length === 0
  const headerDescription =
    selectedUploadType === UploadType.uploadAsASource
      ? "You have selected Upload as Source"
      : "You have selected Master Version"

  const uploadTypeTooltipTitle =
    selectedUploadType === UploadType.uploadAsASource
      ? "Upload as a Source"
      : "Master Version"

  const uploadTypeTooltipBody =
    selectedUploadType === UploadType.uploadAsASource
      ? 'Use this functionality to upload direct master source/record data against an existing or new data source. If a data source doesn\'t exist, select "Add New Source" and follow the workflow to create a new data source for our system to evaluate and analyze.'
      : "Use this functionality to upload data and associate it with an existing data source (retailer integration). Use this to compare what is live vs. what you may have in the PIM (or a master/golden record data source)."

  return (
    <div className="flex flex-col h-full">
      <div className="step-body">
        <>
          <div className="flex justify-between border-b pb-3">
            <div>
              <span className="text-title-3 font-bold">Retailer Association</span>
              <div className="flex">
                <span className="text-body text-fuchsia-700 font-medium pr-2">
                  {headerDescription}
                </span>
                <Tippy
                  placement={"right"}
                  duration={[50, 0]}
                  interactive
                  appendTo={document.body}
                  content={
                    <div className="downgraded-info-point info-point">
                      <div className="text-caption-1 font-medium mb-1">
                        {uploadTypeTooltipTitle}
                      </div>
                      <div className="text-caption-1 text-gray-500">
                        {uploadTypeTooltipBody}
                      </div>
                    </div>
                  }
                >
                  <div className="self-center">
                    <Icon icon={ICONS.INFORMATION_CIRCLE_OUTLINED} size={4.5} />
                  </div>
                </Tippy>
              </div>
            </div>
            <Button onClick={onCancel} variant="outlined" className="self-end">
              Cancel Upload
            </Button>
          </div>
          <div className="h-[calc(100%-59px)] flex flex-col">
            <SelectRetailerUncontrolled
              selectedRetailers={selectedRetailers}
              setSelectedRetailers={setSelectedRetailers}
              selectedUploadType={selectedUploadType}
            />
          </div>
        </>
      </div>
      <div className="step-button-bar sticky bottom-0 bg-white z-10">
        <Button
          variant="outlined"
          onClick={() => {
            setStep(step - 1)
          }}
          className="mr-3"
        >
          Back
        </Button>
        <Button
          variant="primary"
          disabled={isNextStepDisabled}
          onClick={() => {
            importDataMapping()
          }}
        >
          Next Step
        </Button>
      </div>
    </div>
  )
}
